import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import {
  POST_PROFILE_URL,
  UPDATE_PROFILE_URL,
} from "../../enpoints";
import { getCookieValue } from "../../authSlide";
import getAvatar, { getAllAvatars } from "../../avatars";
import AvatarList from "./avatar/avatar";
import Unlockables from "./unlockables/unlockables";
import Label from "../logIn/label";
import Input from "../logIn/input";
import { cn } from "../../utils/cn";
import Navbar from "../navBar/navBar";

const Profile = () => {
  const [formData, setFormData] = useState({
    token: "",
    name: "",
    username: "",
    email: "",
    password: "",
    nivel: "",
  });
  const [showProfile, setShowProfile] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [changePass, setChangePass] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const navigate = useNavigate();
  const avatar = getCookieValue("avatar");
  const stars = Array.from({ length: 100 }, (_, index) => index);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "password") {
      setChangePass(true);
    }
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleDisconect = () => {
    document.cookie = "isAuthenticated=true; path=/; max-age=7200; samesite=Lax";
    document.cookie = "auth_token=1; path=/; max-age=7200; samesite=Lax";
    navigate("/login");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = {
      token: getCookieValue("auth_token"),
      name: formData.name,
      email: formData.email,
      username: formData.username,
      nivel: formData.nivel,
      ...(changePass && { password: formData.password }),
    };
    callUpdate(data);
  };

  const callUpdate = (data) => {
    axios
      .put(UPDATE_PROFILE_URL, data)
      .then((response) => {
        const { username, email, name, password, nivel } = response.data;
        setFormData((prevData) => ({
          ...prevData,
          username: decodeURIComponent(username),
          email: decodeURIComponent(email),
          name: decodeURIComponent(name),
          password: decodeURIComponent(password),
          nivel: decodeURIComponent(nivel),
        }));
        setShowProfile(true);
        setIsEditing(false);
        toast.done("Información Guardada");
      })
      .catch((error) => {
        console.error("Error al realizar la solicitud:", error);
        const errorMessage = error.response?.data?.error || error.message;
        toast.error("Error al realizar la solicitud:" + errorMessage);
      });
  };

  const handleEditClick = () => {
    setIsEditing(!isEditing);
  };

  const postUser = useCallback(() => {
    const token = getCookieValue("auth_token");
    axios
      .post(POST_PROFILE_URL, { token })
      .then((response) => {
        const { username, email, name, password, nivel } = response.data;
        setFormData({
          token,
          username: decodeURIComponent(username),
          email: decodeURIComponent(email),
          name: decodeURIComponent(name),
          password: decodeURIComponent(password),
          nivel: decodeURIComponent(nivel),
        });
        setShowProfile(true);
        toast.success("Se ha obtenido su informacion");
      })
      .catch((error) => {
        console.error("Error al realizar la solicitud:", error);
        const errorMessage = error.response?.data?.error || error.message;
        toast.error("Error al realizar la solicitud:" + errorMessage);
      });
  }, []);

  useEffect(() => {
    if (!dataLoaded) {
      postUser();
      setDataLoaded(true);
    }
  }, [dataLoaded, postUser]);

  return (
    <div className="back2">
      <ToastContainer position="top-right" autoClose={3000} hideProgressBar={false} />
      <Navbar></Navbar>
      <div className="star-background">
        {stars.map((_, index) => (
          <div
            key={index}
            className="star"
            style={{
              top: `${Math.random() * 100}%`,
              left: `${Math.random() * 100}%`,
              animationDuration: `${Math.random() * 3 + 1}s`,
              animationDelay: `${Math.random() * 2}s`,
            }}
          />
        ))}

        <div className="mt-20 w-[80%] mx-auto rounded-2xl p-4 md:p-8 shadow-input bg-white-100 dark:bg-none flex flex-col items-center justify-center">
          <AvatarList avatarMap={getAllAvatars()} prop_avatar={getAvatar(avatar)} />
          <div className="w-[100%]">
            {isEditing ? (
              <div className="w-[100%] mx-auto rounded-2xl p-4 md:p-8 shadow-input bg-white dark:bg-black">
                <h2 className="font-bold text-xl text-neutral-800 dark:text-neutral-200 mb-2 text-center">
                  Cambie los datos de su perfil
                </h2>
                <form className="my-4" onSubmit={handleSubmit}>
                  <div className="mb-2">
                    <Label htmlFor="name">Name</Label>
                    <Input
                      id="name"
                      name="name"
                      placeholder={formData.name}
                      type="text"
                      value={formData.name}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="mb-2">
                    <Label htmlFor="username">Username</Label>
                    <Input
                      id="username"
                      name="username"
                      placeholder={formData.username}
                      type="text"
                      value={formData.username}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="mb-2">
                    <Label htmlFor="email">Email</Label>
                    <Input
                      id="email"
                      name="email"
                      placeholder={formData.email}
                      type="email"
                      value={formData.email}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="mb-2">
                    <label htmlFor="nivel">Nivel/Curso académico</label>
                    <select
                      id="nivel"
                      name="nivel"
                      className={cn(
                        `flex h-10 w-full border-none bg-gray-50 dark:bg-zinc-800 text-black dark:text-white shadow-input rounded-md px-3 py-2 text-sm  file:border-0 file:bg-transparent 
                        file:text-sm file:font-medium placeholder:text-neutral-400 dark:placeholder-text-neutral-600 
                        focus-visible:outline-none focus-visible:ring-[2px]  focus-visible:ring-neutral-400 dark:focus-visible:ring-neutral-600
                        disabled:cursor-not-allowed disabled:opacity-50
                        dark:shadow-[0px_0px_1px_1px_var(--neutral-700)]
                        group-hover/input:shadow-none transition duration-400
                        `
                      )}
                      value={formData.nivel}
                      onChange={handleChange}
                    >
                      <option value="">Selecciona un curso</option>
                      <option value="1º Primaria">1º Primaria</option>
                      <option value="2º Primaria">2º Primaria</option>
                      <option value="3º Primaria">3º Primaria</option>
                      <option value="4º Primaria">4º Primaria</option>
                      <option value="5º Primaria">5º Primaria</option>
                      <option value="6º Primaria">6º Primaria</option>
                      <option value="1º ESO">1º ESO</option>
                      <option value="2º ESO">2º ESO</option>
                      <option value="3º ESO">3º ESO</option>
                      <option value="4º ESO">4º ESO</option>
                      <option value="1º Bachillerato">1º Bachillerato</option>
                      <option value="2º Bachillerato">2º Bachillerato</option>
                      <option value="Ciclo Formativo de Grado Medio">Ciclo Formativo de Grado Medio</option>
                      <option value="Ciclo Formativo de Grado Superior">Ciclo Formativo de Grado Superior</option>
                      <option value="Grado Universitario">Grado Universitario</option>
                      <option value="Máster Universitario">Máster Universitario</option>
                      <option value="Doctorado">Doctorado</option>
                    </select>
                  </div>
                  <div>
                    <label>Password:</label>
                    <Input
                      type={showPassword ? "text" : "password"}
                      name="password"
                      placeholder="*******"
                      value={formData.password}
                      onChange={handleChange}
                      className="input"
                    />
                  </div>
                  <button type="button" className="nextQuestion" onClick={() => setShowPassword(!showPassword)}>
                    {showPassword ? "Ocultar" : "Mostrar"}
                  </button>
                  <div className="relative top-5 left-1/2 transform -translate-x-1/2 w-full max-w-[90%] flex justify-center space-x-4 mb-10">
                    <button
                      className="relative inline-flex h-12 overflow-hidden rounded-lg p-[1px] focus:outline-none focus:ring-2 focus:ring-slate-400 focus:ring-offset-2 focus:ring-offset-slate-50 dark:focus:ring-offset-slate-800 transition-all duration-300 hover:bg-slate-950 dark:hover:bg-slate-100"
                      type="submit"
                    >
                      <span className="absolute inset-[-1000%] animate-[spin_2s_linear_infinite] bg-[conic-gradient(from_90deg_at_50%_50%,#E2CBFF_0%,#393BB2_50%,#E2CBFF_100%)]" />
                      <span className="inline-flex h-full w-full cursor-pointer items-center justify-center rounded-lg bg-slate-100 dark:bg-slate-950 px-6 py-2 text-sm font-medium text-black dark:text-white backdrop-blur-3xl transition-all duration-300 hover:bg-slate-950 hover:text-white dark:hover:bg-slate-100 dark:hover:text-black">
                        Guardar &uarr;
                      </span>
                    </button>
                    <button
                      className="relative inline-flex h-12 overflow-hidden rounded-lg p-[1px] focus:outline-none focus:ring-2 focus:ring-slate-400 focus:ring-offset-2 focus:ring-offset-slate-50 dark:focus:ring-offset-slate-800 transition-all duration-300 hover:bg-slate-950 dark:hover:bg-slate-100"
                      onClick={handleEditClick}
                    >
                      <span className="absolute inset-[-1000%] animate-[spin_2s_linear_infinite] bg-[conic-gradient(from_90deg_at_50%_50%,#E2CBFF_0%,#393BB2_50%,#E2CBFF_100%)]" />
                      <span className="inline-flex h-full w-full cursor-pointer items-center justify-center rounded-lg bg-slate-100 dark:bg-slate-950 px-6 py-2 text-sm font-medium text-black dark:text-white backdrop-blur-3xl transition-all duration-300 hover:bg-slate-950 hover:text-white dark:hover:bg-slate-100 dark:hover:text-black">
                        Cancelar
                      </span>
                    </button>
                  </div>
                </form>
                <ToastContainer position="top-right" autoClose={3000} hideProgressBar={false} />
              </div>
            ) : (
              <div className="w-[100%] mx-auto">
                <ProfileDetail label="Nombre:" value={formData.name} show={showProfile} />
                <ProfileDetail label="Username:" value={formData.username} show={showProfile} />
                <ProfileDetail label="Email:" value={formData.email} show={showProfile} />
                <ProfileDetail label="Nivel:" value={formData.nivel} show={showProfile} />
                <ProfileDetail label="Contraseña:" value="*********" show={showProfile} />
                <div className="relative top-5 left-1/2 transform -translate-x-1/2 w-full max-w-[90%] flex justify-center space-x-4 mb-10">
                  <button
                    className="relative inline-flex h-12 overflow-hidden rounded-lg p-[1px] focus:outline-none focus:ring-2 focus:ring-slate-400 focus:ring-offset-2 focus:ring-offset-slate-50 dark:focus:ring-offset-slate-800 transition-all duration-300 hover:bg-slate-950 dark:hover:bg-slate-100"
                    onClick={handleEditClick}
                  >
                    <span className="absolute inset-[-1000%] animate-[spin_2s_linear_infinite] bg-[conic-gradient(from_90deg_at_50%_50%,#E2CBFF_0%,#393BB2_50%,#E2CBFF_100%)]" />
                    <span className="inline-flex h-full w-full cursor-pointer items-center justify-center rounded-lg bg-slate-100 dark:bg-slate-950 px-6 py-2 text-sm font-medium text-black dark:text-white backdrop-blur-3xl transition-all duration-300 hover:bg-slate-950 hover:text-white dark:hover:bg-slate-100 dark:hover:text-black">
                      Editar Perfil &rarr;
                    </span>
                  </button>
                  <button
                    className="relative inline-flex h-12 overflow-hidden rounded-lg p-[1px] focus:outline-none focus:ring-2 focus:ring-slate-400 focus:ring-offset-2 focus:ring-offset-slate-50 dark:focus:ring-offset-slate-800 transition-all duration-300 hover:bg-slate-950 dark:hover:bg-slate-100"
                    onClick={handleDisconect}
                  >
                    <span className="absolute inset-[-1000%] animate-[spin_2s_linear_infinite] bg-[conic-gradient(from_90deg_at_50%_50%,#E2CBFF_0%,#393BB2_50%,#E2CBFF_100%)]" />
                    <span className="inline-flex h-full w-full cursor-pointer items-center justify-center rounded-lg bg-slate-100 dark:bg-slate-950 px-6 py-2 text-sm font-medium text-black dark:text-white backdrop-blur-3xl transition-all duration-300 hover:bg-slate-950 hover:text-white dark:hover:bg-slate-100 dark:hover:text-black">
                      Desconectarse &larr;
                    </span>
                  </button>
                </div>
                <ToastContainer position="top-right" autoClose={3000} hideProgressBar={false} />
              </div>
            )}
          </div>
        </div>
        <Unlockables number_rows={10} />

      </div>
    </div>
  );
};

const ProfileDetail = ({ label, value, show }) => (
  <>
    <div className="mb-4 flex flex-col">
      <div className="text-lg text-white font-semibold">{label}</div>
      {show && <div className="text-base text-gray-200">{value}</div>}
    </div>
    <div className="bg-gradient-to-r from-transparent via-blue-300 dark:via-blue-700 to-transparent my-4 h-[1px] w-full" />
  </>
);

export default Profile;
