import React from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import "./App.css";
import LogIn from "./components/logIn/logIn";
import Mainpage from "./components/mainPage/mainPage";
import Profile from "./components/profile/profile";
import SignUp from "./components/signUp/singUp";
import { Provider } from "react-redux";
import Questionary from "./components/questionary/questionary";
import store from "./store";
import QuestionsMenu from "./components/questionMode/questionsMenu";
import Stats from "./components/stats/showStats";
import Thinkers from "./components/thinkers/thinkers";
import Describe from "./components/describe/describe";
import BdpAI from "./components/bdpAI/bdpAI";
import { Helmet } from "react-helmet";
import PrivacyPolicy from "./components/legal/privacyPolitic";
import AboutMe from "./components/aboutMe/aboutMe"
import { NewDescribe } from "./components/newDescribe/newDescribe";
import AddExpense from "./components/economy/addExpense";

function ProtectedRoute({ children }) {
  const allCookies = document.cookie;

  const isAuthenticated = allCookies.includes("isAuthenticated=true");
  if (!isAuthenticated) {
    return <Navigate to="/describe" replace />;
  }

  return children;
}

function App() {
  return (
    <Provider store={store}>
      <Router>
        <Routes>
          <Route path="/" element={<ProtectedRoute><Mainpage /> </ProtectedRoute>} />

          <Route path="/profile" element={<ProtectedRoute><Profile /></ProtectedRoute>} />
          <Route path="/questionary" element={<ProtectedRoute><Questionary /></ProtectedRoute>} />
          <Route path="/thinkers" element={<ProtectedRoute><Thinkers /></ProtectedRoute>} />
          <Route path="/questions" element={<ProtectedRoute><QuestionsMenu /></ProtectedRoute>} />
          <Route path="/stats" element={<ProtectedRoute><Stats /></ProtectedRoute>} />
          <Route path="/bdpAI" element={<ProtectedRoute><BdpAI /></ProtectedRoute>} />
          <Route path="/economy" element={<ProtectedRoute><AddExpense /></ProtectedRoute>} />

          <Route path="/describe" element={<Describe />} />
          <Route path="*" element={<Navigate to="/logIn" />} />
          <Route path="/privacy_politic" element={<PrivacyPolicy></PrivacyPolicy>} />
          <Route path="/aboutMe" element={<AboutMe></AboutMe>} />
          <Route path="/signUp" element={<SignUp />} />
          <Route path="/logIn" element={<LogIn />} />
        </Routes>
      </Router>
    </Provider>
  );
}


export default App;
