import React from "react";
import { DescribeParralax } from "./describeParralax";
import getArchi from "../../achiv";
export function NewDescribe() {
  return <DescribeParralax products={products} />;
}
export const products = [
  {
    title: "Thinkers",
    link: "https://quizz4learn.com/thinkers",
    thumbnail:getArchi('atgold'),
  },
  {
    title: "Thinkers",
    link: "https://quizz4learn.com/thinkers",
    thumbnail:getArchi('atsilv'),
  },
];
