"use client";
import React from "react";
import { Carousel, Card } from "../../utils/carruselCards";
import Stats from "../stats/showStats";
import getArchi from "../../achiv";
import Image from "../newDescribe/image";
import Unlockables from "../profile/unlockables/unlockables";

export function Carrousel() {
  const cards = data.map((card, index) => (
    <Card key={card.src} card={card} index={index} />
  ));

  return (
    <div className="w-full h-full py-20">
      <h2 className="max-w-7xl pl-4 mx-auto text-xl md:text-5xl font-bold text-neutral-200 dark:text-neutral-200 font-sans">
        Welcome to the Quizz4Learn
      </h2>
      <Carousel items={cards} />
    </div>
  );
}

const DummyContent = () => {
  return (
    <>
      <div
        className="bg-[#F5F5F7] dark:bg-neutral-800 p-8 md:p-14 rounded-3xl mb-4"
      >
        <p className="text-neutral-600 dark:text-neutral-400 text-base md:text-2xl font-sans max-w-3xl mx-auto">
          <span className="font-bold text-neutral-700 dark:text-neutral-200">
            Notas del Parche 0.0.2
          </span>{" "}
          <p>- Corregido algunos errores en la interfaz para móviles</p>
          <p>- Añadidas nuevas funcionalidades</p>
          <p>- Ahora se pueden crear partidas privadas de thinkers</p>
          <p>- Se ha remodelado la pantalla inicial</p>
        </p>
      </div>
      <div
        className="bg-[#F5F5F7] dark:bg-neutral-800 p-8 md:p-14 rounded-3xl mb-4"
      >
        <p className="text-neutral-600 dark:text-neutral-400 text-base md:text-2xl font-sans max-w-3xl mx-auto">
          <span className="font-bold text-neutral-700 dark:text-neutral-200">
            Notas del Parche 0.0.1
          </span>{" "}
          <p>- Bienvenidos a Quizz4Learn!!</p>
          <p>- Compite aprende y diviertete</p>
        </p>
      </div>
    </>
  );
};

const data = [
  {
    category: "Notas del parche",
    title: "PRE-ALPHA 0.0.2",
    src: getArchi("zeusgold"),
    content: <DummyContent />,
  },
  {
    category: "Estadísticas",
    title: "AQUI!!!",
    src: getArchi("trophy"),
    content: <Stats></Stats>,
  },
  {
    category: "Desbloquea logros 🏆",
    title: "Y además gana recompensas!! (se está trabajando en ello)",
    src: getArchi("stelar"),
    content: <Unlockables number_rows={10} />,
  },
];
