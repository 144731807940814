import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import axios from 'axios';
import { motion } from 'framer-motion';
import { POST_BDP_AI_URL } from '../../enpoints';
import { toast, ToastContainer } from "react-toastify";
import { getCookieValue } from '../../authSlide';
import PropTypes from "prop-types";
import LoadingSpinner from './loadinAI'; // Asegúrate de tener este componente

const UploadQuestions = ({ setStart, setQuestions }) => {
  const [file, setFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false); // Estado para el componente de espera
  const token = getCookieValue("auth_token");

  const onDrop = useCallback((acceptedFiles) => {
    setFile(acceptedFiles[0]);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      'application/pdf': ['.pdf'],
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
      'application/msword': ['.doc']
    }
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!file) {
      toast.warn("Por favor, suba un archivo primero"); // Avisa al usuario si no hay archivo
      return;
    }

    const formData = new FormData();
    formData.append('file', file);
    formData.append('token', token);  // Append the token to the form data

    setIsLoading(true); // Mostrar componente de espera

    try {
      const response = await axios.post(POST_BDP_AI_URL, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      if (response.data.error !== undefined) {
        toast.error(response.data.error + ", seguramente se deba a que el tamaño del documento es demasiado grande")
        setStart(false);
      }else{
        toast.info("Se ha generado correctamente");
        console.log('File uploaded successfully:', response.data);
        setQuestions(response.data.gpt);
        setStart(true);
      }
    } catch (error) {
      toast.error("Ha habido un error al subir el documento");
      console.error('Error uploading file:', error);
    } finally {
      setIsLoading(false); // Ocultar componente de espera
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0.0, y: 40 }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{
        delay: 0.3,
        duration: 0.8,
        ease: 'easeInOut',
      }}
      className="relative flex flex-col gap-4 items-center justify-center px-4"
    >
      <div className="max-w-full w-full sm:w-[30rem] md:w-[40rem] lg:w-[50rem] max-h-[90vh] overflow-y-auto mx-auto rounded-2xl p-4 md:p-8 shadow-input bg-white dark:bg-black">
        <div {...getRootProps()} className="border-2 border-dashed border-gray-300 p-6 text-center cursor-pointer">
          <input {...getInputProps()} />
          <p className="text-neutral-800 dark:text-neutral-200">
            Arrastra y suelta un archivo Word o PDF aquí, o haz clic para seleccionar un archivo
          </p>
        </div>
        {file && (
          <div className="mt-4">
            <h3 className="text-lg font-bold text-neutral-800 dark:text-neutral-200 mb-2">Archivo Cargado</h3>
            <p className="text-neutral-800 dark:text-neutral-200">{file.name}</p>
          </div>
        )}
        <form className="my-4 w-full" onSubmit={handleSubmit}>
          <div className="flex justify-center">
            <button
              className="relative inline-flex h-12 overflow-hidden rounded-full p-[1px] focus:outline-none focus:ring-2 focus:ring-slate-400 focus:ring-offset-2 focus:ring-offset-slate-50 dark:focus:ring-offset-slate-800 transition-all duration-300 hover:bg-slate-950 dark:hover:bg-slate-100"
              type="submit"
            >
              <span className="absolute inset-[-1000%] animate-[spin_2s_linear_infinite] bg-[conic-gradient(from_90deg_at_50%_50%,#E2CBFF_0%,#393BB2_50%,#E2CBFF_100%)]" />
              <span className="inline-flex h-full w-full cursor-pointer items-center justify-center rounded-full bg-slate-100 dark:bg-slate-950 px-6 py-2 text-sm font-medium text-black dark:text-white backdrop-blur-3xl transition-all duration-300 hover:bg-slate-950 hover:text-white dark:hover:bg-slate-100 dark:hover:text-black">
                Subir Archivo &rarr;
              </span>
            </button>
          </div>
        </form>
        <ToastContainer position="top-right" autoClose={3000} hideProgressBar={false} />
        {isLoading && <LoadingSpinner />} {/* Mostrar el componente de espera */}
      </div>
    </motion.div>
  );
};

UploadQuestions.propTypes = {
  setStart: PropTypes.func.isRequired,
  setQuestions: PropTypes.func.isRequired,
};

export default UploadQuestions;
