import React, { useState } from "react";
import "./bdpAI.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getCookieValue } from "../../authSlide";
import { TextGenerateEffectWhite } from "../describe/generateText";
import UploadQuestions from "./uploadFiles";
import BdpAIDisplayer from "./bdpAIDisplayer";
import JsonToPdf from "./downloadFiles";
import { POST_CREATE_THINKER } from "../../enpoints";
import axios from "axios";
import { FaCopy } from 'react-icons/fa'; // Importa el ícono de copiar
import 'react-toastify/dist/ReactToastify.css';
import Navbar from "../navBar/navBar";


export function BdpAI() {
  const [start, setStart] = useState(false);
  const [game, setGame] = useState(false);
  const [questions, setQuestions] = useState([]);
  const words2 = "Suba un documento y genere un exámen con preguntas aleatorias para prepararse para el exámen gracias a la Inteligencia Artificial (solo usará el texto)"
  const [thinkerId, setThinkerId] = useState(null);

  const handleCopyToClipboard = () => {
    if (thinkerId) {
      if (navigator.clipboard && navigator.clipboard.writeText) {
        navigator.clipboard.writeText(thinkerId)
          .then(() => {
            toast.success("ID copiado correctamente");
          })
          .catch(() => {
            toast.error("No se ha podido copiar el ID");
          });
      } else {
        // Fallback para dispositivos que no soportan navigator.clipboard
        const textArea = document.createElement("textarea");
        textArea.value = thinkerId;
        textArea.style.position = "fixed";  // Evita que el área de texto sea visible
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        try {
          document.execCommand('copy');
          toast.success("ID copiado correctamente");
        } catch (err) {
          toast.error("No se ha podido copiar el ID");
        }
        document.body.removeChild(textArea);
      }
    }
  };

  const handleStartGame = () => {
    setGame(true)
  }

  const handleCreateGame = () => {
    const jsonData = {
      token: getCookieValue("auth_token"),
      questions: questions
    }
    axios
      .post(POST_CREATE_THINKER, jsonData)
      .then((response) => {
        setThinkerId(response.data.id)
        toast.done("Partida privada creada correctamente")
      })
      .catch((error) => {
        console.error("Error al realizar la solicitud:", error);
        if (error.response === undefined || error.response.data.error === undefined) {
          toast.error("Error al realizar la solicitud:" + error.message);
        } else {
          toast.error("Error al realizar la solicitud:" + error.response.data.error);
        }
      });
  }

  return (
    <div className="back2">
      <Navbar></Navbar>
      <div className="page">
        {/* CAMBIAR a !game para que se vea todo lo de IA*/}
        {game && (
          <div>
            <div className="text-3xl md:text-7xl font-bold text-white text-center">
              TheQuizzBDP AI
            </div>
            <TextGenerateEffectWhite
              words={words2}
              className="font-extralight text-base md:text-4xl text-neutral-200 py-4"
            />
            <UploadQuestions setStart={setStart} setQuestions={setQuestions} />
          </div>
        )}
        {game && <BdpAIDisplayer questions_prop={questions} />}
        {start &&
          <div className="flex justify-center my-8 space-y-4 flex-col">
            <button
              onClick={() => handleStartGame()}
              className="relative inline-flex h-12 overflow-hidden rounded-full p-[1px] focus:outline-none focus:ring-2 focus:ring-slate-400 focus:ring-offset-2 focus:ring-offset-slate-50 dark:focus:ring-offset-slate-800 transition-all duration-300 hover:bg-slate-950 dark:hover:bg-slate-100"
            >
              <span className="absolute inset-[-1000%] animate-[spin_2s_linear_infinite] bg-[conic-gradient(from_90deg_at_50%_50%,#E2CBFF_0%,#393BB2_50%,#E2CBFF_100%)]" />
              <span className="inline-flex h-full w-full cursor-pointer items-center justify-center rounded-full bg-slate-100 dark:bg-slate-950 px-6 py-2 text-sm font-medium text-black dark:text-white backdrop-blur-3xl transition-all duration-300 hover:bg-slate-950 hover:text-white dark:hover:bg-slate-100 dark:hover:text-black">
                Empezar Exámen
              </span>
            </button>
            <JsonToPdf data={questions} />
            <button
              onClick={handleCreateGame}
              className="relative inline-flex h-12 overflow-hidden rounded-full p-[1px] focus:outline-none focus:ring-2 focus:ring-slate-400 focus:ring-offset-2 focus:ring-offset-slate-50 dark:focus:ring-offset-slate-800 transition-all duration-300 hover:bg-slate-950 dark:hover:bg-slate-100"
            >
              <span className="absolute inset-[-1000%] animate-[spin_2s_linear_infinite] bg-[conic-gradient(from_90deg_at_50%_50%,#E2CBFF_0%,#393BB2_50%,#E2CBFF_100%)]" />
              <span className="inline-flex h-full w-full cursor-pointer items-center justify-center rounded-full bg-slate-100 dark:bg-slate-950 px-6 py-2 text-sm font-medium text-black dark:text-white backdrop-blur-3xl transition-all duration-300 hover:bg-slate-950 hover:text-white dark:hover:bg-slate-100 dark:hover:text-black">
                Crear Thinkers privado
              </span>
            </button>
            {thinkerId && (
              <div className="mt-4 flex items-center space-x-2">
                <p className="text-lg font-medium text-white dark:text-white">
                  Thinkers Privado: {thinkerId}
                </p>
                <button
                  onClick={handleCopyToClipboard}
                  className="inline-flex h-10 w-10 items-center justify-center rounded-full bg-slate-200 dark:bg-slate-700 text-black dark:text-white hover:bg-slate-300 dark:hover:bg-slate-600 focus:outline-none focus:ring-2 focus:ring-slate-400 focus:ring-offset-2 focus:ring-offset-slate-50 dark:focus:ring-offset-slate-800 transition-all duration-300"
                >
                  <FaCopy />
                </button>
              </div>
            )}
          </div>
        }
        <div className="text-1xl md:text-1xl font-bold text-white text-center">
          Tenga en cuenta que el modelo podría entrenarse con el documento que suba y/o la repuesta utilizada. ión incorrecta. Es posible que la inteligencia artificial genere información incorrecta
        </div>
      </div>
      <ToastContainer position="top-right" autoClose={3000} hideProgressBar={false} />
    </div>
  );
}

export default BdpAI;
