import { useState } from 'react';
import getArchi from '../../achiv';
import getAvatar from '../../avatars';
import { getCookieValue } from '../../authSlide';
import { useNavigate } from 'react-router-dom';

const Navbar = () => {
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

    const navigate = useNavigate()

    const toggleMobileMenu = () => {
        setIsMobileMenuOpen(!isMobileMenuOpen);
    };

    return (
        <nav className="bg-white dark:bg-gray-800 shadow-md fixed w-full h-12 top-0 z-50">
            <div className="mx-auto flex justify-between items-center px-20">
                <div className="flex items-center space-x-4">
                    <a className="relative flex items-center space-x-4 w-60 left-0"  href='/'>
                        <img src={getArchi("atgold")} alt="Logo" className="rounded h-12 w-12"/>
                        <span className="text-xl font-bold text-black dark:text-white">Quizz4Learn</span>
                    </a>
                </div>
                <div>
                    <div className="hidden md:flex space-x-4">
                        <a href="/" className="text-black dark:text-white hover:underline">Inicio</a>
                        <a href="/questions" className="text-black dark:text-white hover:underline">Entrenamiento</a>
                        <a href="/thinkers" className="text-black dark:text-white hover:underline">Thinkers</a>
                        <a href="/bdpAI" className="text-black dark:text-white hover:underline">BDP AI</a>
                        <a href="/aboutMe" className="text-black dark:text-white hover:underline">Q4L</a>
                        <a href="/economy" className="text-black dark:text-white hover:underline">Finanzas</a>

                    </div>
                </div>

                <div className="flex items-center space-x-4">
                    <button className="absolute top-3 right-3 md:hidden block text-black dark:text-white" onClick={toggleMobileMenu}>
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7" />
                        </svg>
                    </button>
                </div>

                <button onClick={() => navigate('/profile')} className="relative top-0 z-10 block h-10 w-10 rounded-full overflow-hidden shadow focus:outline-none focus:ring">
                    <img className="h-full w-full object-cover" src={getAvatar(getCookieValue("avatar"))} alt="Your avatar" />
                </button>
            </div>

            {isMobileMenuOpen && (
                <button className="md:hidden rounded absolute right-0 z-20 w-48  bg-white dark:bg-gray-800 mt-2 flex flex-col space-y-2 px-4">
                    <a href="/" className="text-black dark:text-white hover:underline">Inicio</a>
                    <a href="/questions" className="text-black dark:text-white hover:underline">Entrenamiento</a>
                    <a href="/thinkers" className="text-black dark:text-white hover:underline">Thinkers</a>
                    <a href="/bdpAI" className="text-black dark:text-white hover:underline">BDP AI</a>
                    <a href="/aboutMe" className="text-black dark:text-white hover:underline">Q4L</a>
                    <a href="/economy" className="text-black dark:text-white hover:underline">Finanzas</a>

                </button>
            )}
        </nav>
    );
};

export default Navbar;
